@tailwind base;
@tailwind components;
@tailwind utilities;

@media (max-width: 768px) {
    .container { flex-direction: column;
    }
}

.container {
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    padding-left: 10px;
    padding-right: 10px;
}

.emptyDivide{
    height: 24px;
}

html,body,#root {
    height: 100%;
    /*height:100vh;*/
    width: 100%;
    margin: auto;
}
#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 320px;
    max-width: 448px;
}
