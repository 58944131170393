/*@keyframes scroll {*/
/*    0% {*/
/*        transform: translateX(0%);*/
/*    }*/
/*    100% {*/
/*        transform: translateX(-100%);*/
/*    }*/
/*}*/

/*.scroll-text {*/
/*    display: inline-block;*/
/*    white-space: nowrap;*/
/*    animation: scroll 10s linear infinite forwards;*/
/*}*/

.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0,0,0); /* Black fallback color */
    background-color: rgba(0,0,0, 0.9); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}
